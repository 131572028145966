<template>
  <div class="home_" v-if="isinit" :style="cssVars">
    <div class="banner">
      <div class="main">
        <img src="../assets/img/logo2022.png" alt="" />
        <h1>全球互联网架构大会<p>GLOBAL INTERNET ARCHITECTURE CONFERENCE</p></h1>
        <p>
          <span>连接未来的技术架构</span>
        </p>
        <div class="time">
          <p>
            <!-- <i class="glyphicon glyphicon-time"></i> -->
            <img src="../assets/img/shijian.png" alt="" /> {{ time }}
          </p>
          <p>
            <!-- <i class="glyphicon glyphicon-map-marker"></i> -->
            <img src="../assets/img/didian.png" alt="" />{{ place }}
          </p>
        </div>
        <div class="btns">
          <p class="act" @click="toOrder()">GIAC 深圳站报名中</p>
          <!-- <p @click="toNext('/2024sz/home')">GIAC 深圳站</p> -->
        </div>
      </div>
    </div>

    <div class="giac-container top">
      <div class="main">
        <div class="desc">
          <div class="le">
            <h2>会议介绍</h2>
            <p>
              全球互联网架构大会(简称“GIAC”)是长期关注互联网技术与架构的高可用架构技术社区和msup推出的面向架构师、技术负责人及高端技术从业人员的年度技术架构大会，是中国地区规模最大的技术会议之一。
            </p>
          </div>
          <div class="ri">
            <div class="titem">
              <h1>24</h1>
              <p class="pc">分会场主题<br />覆盖主流架构话题</p>
              <p class="mob">
                <strong>分会场主题</strong><br />覆盖主流架构话题
              </p>
            </div>
            <div class="titem">
              <h1>84</h1>
              <p class="pc">热门案例<br />深度解密架构演进</p>
              <p class="mob"><strong>热门案例</strong><br />深度解密架构演进</p>
            </div>
            <div class="titem">
              <h1>1000+</h1>
              <p class="pc">架构师<br />共同学习共同进步</p>
              <p class="mob"><strong>架构师</strong><br />共同学习共同进步</p>
            </div>
          </div>
        </div>
        <p class="mob">
          <span>全球互联网架构大会(简称“GIAC”)</span
          >是长期关注互联网技术与架构的高可用架构技术社区和msup推出的面向架构师、技术负责人及高端技术从业人员的年度技术架构大会，是中国地区规模最大的技术会议之一。
        </p>
        <Speakers :_speakers="speakers"></Speakers>
        <!--  v-if="checkBoolean(speaker)" -->
        <p class="more pc">更多大咖正在邀请确认中……</p>
      </div>
      <div class="hshadow"></div>
    </div>

    <div class="theme-setting">
      <div class="giac-container">
        <div class="main">
          <h1>GIAC议题设置</h1>
          <p>
            本届GIAC议题共设置有24个主题，覆盖各类架构热点领域，每个主题由业内知名架构师、技术负责人等专家担任出品人，负责议题选取和质量把控。
          </p>
          <div class="pc">
            <div class="list">
              <div
                class="litem"
                :class="theme == index ? 'act' : ''"
                v-for="(item, index) in themeList"
                :key="'home_theme_' + index"
                @click="setTheme($event,index,'pc')"
              >
                <slot v-for="(titem, tindex) in item">
                  {{ tindex > 0 ? "/" : "" }}{{ titem.venueName }}
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="giac-container pc">
        <div class="main" v-if="themeList && themeList.length">
          <div
            class="intro"
            v-for="(item, index) in themeList[theme]"
            :key="'home_intro_' + index"
          >
            <div class="left">
              <h1>{{ item.venueName }}</h1>
              <p>出品人：{{ item.name }}</p>
            </div>
            <div class="right" v-if="!isDifference(item.date, item.groupId, item.times) || !item.schedulingCourse || item.schedulingCourse.length==0">
              <div
                class="course"
                v-for="(titem, cindex) in item.times.split(',')"
                :key="'home_intro_course_' + cindex"
                style="width: 25%;"
              >
                <!-- :style="`width:${100 / item.times.split(',').length}%;`" -->
                <!-- {{titem.split('-')[0]}} {{titem.split('-')[1]}} -->
                <slot v-for="(citem, cindex) in item.schedulingCourse">
                  <div
                    class="box"
                    v-if="citem && citem.startTime == titem.split('-')[0]"
                    :key="'scheduling_course_' + index + '_' + cindex"
                  >
                    <h2 @click="toNext('/2024sz/course',{id:citem.courseid})">
                      《{{ citem.courseTitle }}》
                    </h2>
                    <p @click="toNext('/2024sz/course',{id:citem.courseid})">
                      {{ citem.name }} {{ citem.company }} {{ citem.position }}
                    </p>
                  </div>
                </slot>
                <div class="box none">
                  <h1>敬请期待</h1>
                </div>
              </div>
              <div class="course" v-if="item.times.split(',').length<4" style="width: 25%;">
                <div class="box"></div>
              </div>
            </div>
            <div class="right" v-else>
              <div
                class="course"
                v-for="(sitem, sindex) in item.schedulingCourse"
                :key="'home_intro_course_' + sindex"
                :style="`width: ${100/item.schedulingCourse.length}%;`"
              >
                <div class="box">
                  <h2 @click="sitem.courseid ? toNext('/2024sz/course',{id:sitem.courseid}) : ''">
                    《{{ sitem.courseTitle }}》
                  </h2>
                    <p @click="sitem.courseid ? toNext('/2024sz/course',{id:sitem.courseid}) : ''">
                      {{ sitem.name }} {{ sitem.company }} {{ sitem.position }}
                    </p>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <p class="isblank" v-else>敬请期待</p>
      </div>
      <div class="giac-container mob" id="home_themes" v-if="!ispc">
        <!-- collapse -->
        <slot v-if="themeList && themeList.length">
          <div
            class="theme"
            :class="['theme_' + index, theme == index ? 'act' : '']"
            v-for="(item, index) in themeList"
            :key="'home_theme_mob_' + index"
          >
            <!-- head -->
            <div class="theme_head" 
                :class="theme == index ? 'act' : ''"
                :id="'header_'+index" 
                @click.prevent="setTheme($event,index,'mob')"
                :data-target="'#theme_list_' + index" 
                data-toggle="collapse"
                aria-expanded="false"
                :aria-controls="'theme_list_' + index"
            >
              <slot v-for="(titem, tindex) in item">
                {{ tindex > 0 ? "/" : "" }}{{ titem.venueName }}
              </slot>
              <span
                :class="theme == index ? 'open' : 'close'"
              ></span>
            </div>
            <!-- body -->
            <div class="collapse" :id="'theme_list_' + index" data-parent="#home_themes" :aria-labelledby="'header_'+index">
              <div
                class="theme_detail"
                v-for="(titem, tindex) in item"
                :key="'home_theme_mob_' + index + '_' + tindex"
              >
                <!-- title -->
                <div class="theme_title"
                    :id="'header_'+index+'_'+tindex" 
                    :data-target="'#theme_list_' + index + '_' + tindex" 
                    data-toggle="collapse"
                    aria-expanded="false"
                    :aria-controls="'theme_list_' + index + '_' + tindex"
                >
                  {{ titem.venueName }}
                </div>
                <!-- list -->
                <div class="collapse" :id="'theme_list_' + index + '_' + tindex" :data-parent="'#theme_list_' + index" :aria-labelledby="'header_'+index+'_'+tindex">
                  <p v-if="!titem.schedulingCourse||titem.schedulingCourse.length==0" class="no_course">主题下暂无课程</p>
                  <slot v-else>
                    <div class="theme_course"
                        v-for="(citem,cindex) in titem.schedulingCourse"
                        @click="citem.courseid ? toNext('/2024sz/course',{id:citem.courseid}) : ''"
                        :key="'theme_course_' + index + '_' + tindex + '_' + cindex"
                    >
                      <h3>{{citem.courseTitle}}</h3>
                      <p>{{citem.name}} {{citem.company}} {{citem.position}}</p>
                    </div>
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </slot>
        <p class="isblank" v-else>敬请期待</p>
      </div>
    </div>

    <div class="giac-container cases">
      <div class="main">
        <h1>
          本期精选案例
          <slot v-if="caseList.length>caseRenderLength">
            <img class="refresh" @click="refreshCases" src="../assets/img/refresh.png" alt="" >
          </slot>
          <!-- {{ caseRenderLength }} -->
        </h1>
        <p></p>
        <!-- <p>全球互联网架构大会(简称“GIAC”)</p> -->
        <selected-case :caseList="caseRender"></selected-case>
        <div class="case-wrapper" v-if="caseList.length > 0">
          <router-link class="all-case" to="/case">查看全部案例</router-link>
        </div>
      </div>
    </div>

    <div class="giac-container comment-content">
      <img class="pc" src="../assets/img/home-comment-bg-2022-small.png" width="100%" />
      <img class="mob" src="../assets/img/mob-comment-bg-small.png" width="100%" />
      <!-- <img class="pc" src="../assets/img/home-comment-bg-2022.png" width="100%" />
      <img class="mob" src="../assets/img/mob-comment-bg.png" width="100%" /> -->
      <!-- <img class="pc" src="https://f.msup.com.cn/meeting/home-comment-bg-2022.png" width="100%" />
      <img class="mob" src="https://f.msup.com.cn/meeting/mob-comment-bg.png" width="100%"> -->
      <div class="main">
        <h1>参会学员收获</h1>
        <Swiper :textList="commentList"></Swiper>
      </div>
    </div>

    <div class="giac-container conferee">
      <div class="main">
        <slot v-if="false">
          <h1>联合主办方</h1>
          <!-- <p>全球互联网架构大会(简称“GIAC”)</p> -->
          <div class="pc" style="padding-bottom:50px;">
            <div class="companys" v-if="uniteList && uniteList.length>0">
              <div class="list pc">
                <div class="line" style="padding-left:0;justify-content:center;">
                  <div class="litem" 
                    v-for="(item, index) in uniteList"
                    :style="'width:'+100/confereeItem+'%;'"
                    :key="'unite'+index"
                  >
                    <div class="box">
                      <img :src="item.file" alt="" @click="toNew(item.content)">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="companys" v-else-if="sponsorimg!=''">
              <div class="list pc">
                <div class="line" style="padding-left:0;justify-content:center;">
                  <div class="litem" :style="'width:'+100/confereeItem+'%;'">
                    <div class="box">
                      <img :src="sponsorimg" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mob" style="padding-bottom:35px;">
            <div class="companys" v-if="uniteList && uniteList.length>0">
              <div class="citem"
                v-for="(item, index) in uniteList"
                :key="'unite'+index"
              >
                <div class="box">
                  <img :src="item.file" alt="" @click="toNew(item.content)">
                </div>
              </div>
            </div>
            <div class="companys" v-else-if="sponsorimg!=''">
              <div class="citem">
                <div class="box">
                  <img :src="sponsorimg" alt="">
                </div>
              </div>
            </div>
          </div>
        </slot>
        <h1>历史参会企业</h1>
        <!-- <p>全球互联网架构大会(简称“GIAC”)</p> -->
        <div class="pc">
          <div class="companys">
            <div class="left"></div>
            <div class="right"></div>
            <div class="list pc" v-if="conferee">
              <div
                class="line"
                :class="confereeList.length < confereeItem ? 'one' : ''"
                v-for="num in Math.ceil(confereeList.length / confereeItem)"
                :key="'home_conferee_line_' + num + '_' + confereeItem"
              >
                <div
                  class="litem"
                  :style="'width:' + 100 / confereeItem + '%;'"
                  v-for="(item, index) in confereeList.slice(
                    (num - 1) * confereeItem,
                    num * confereeItem
                  )"
                  :key="'home_conferee_item_' + num + '_' + index"
                >
                  <div class="box">
                    <img v-lazy="item.file" alt="" @click="toNew(item.content)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mob">
          <div class="companys">
            <div class="citem"
                v-for="(item, index) in confereeList"
                :key="'home_conferee_item_mob_' + index"
            >
              <div class="box">
                <img v-lazy="item.file" alt="" @click="toNew(item.content)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="giac-container sponsor" ref="home_sponsor_2024sz">
      <div class="main">
        <sponsor-list :_from="'source_home'" :_ispc="ispc"></sponsor-list>
      </div>
    </div>

    <div class="giac-container invite">
      <div class="pc">
        <div class="main">
          <img src="../assets/img/invite.png" alt="" />
          <p>第{{session}}届2024年GIAC全球互联网架构大会邀请函</p>
          <div class="link">
            <a v-if="global.year==theyear" @click="toOrder">
              <p>立即报名</p>
            </a>
            <a v-else>
              <p>已售罄</p>
            </a>
            <a :href="caseurl" @click.prevent="(!caseurl||caseurl=='')?($toast('敬请期待')):''" target="_blank">
              <p>案例征集</p>
            </a>
          </div>
        </div>
      </div>
      <div class="mob">
        <div class="main">
          <div class="left">
            <img src="../assets/img/invite.png" alt="" />
            <p>第{{session}}届2024年GIAC全球互联网架构大会邀请函</p>
          </div>
          <div class="link">
            <a v-if="global.year==theyear" @click="toOrder">
              <p>立即报名</p>
            </a>
            <a v-else>
              <p>已售罄</p>
            </a>
            <a :href="caseurl" @click.prevent="(!caseurl||caseurl=='')?($toast('敬请期待')):''" target="_blank">
              <p>案例征集</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  id_arr,
  caseurl,
  sid,
  scheduledifference,
  scheduleDifferenceId,
  session,
  sidsponsorunite,
  sponsorimg,
  getMeetingTime,
  getSueLecturer,
  sidlecturer1,
  sidlecturer2,
  sidcase,
  getAllCourse,
  getSpeechInfoNew,
  getSue,
  sidsponsorhistory,
  sidsponsor,
  getThemeInfo,
  getUserInfo,
  getSueArr,
  getSueType, 
} from "@/utils/api.js";

export default {
  name: "Home_",
  components: {
    Speakers: () => import("@/components/Speakers.vue"),
    SelectedCase: () => import("@/components/SelectedCaseNew.vue"),
    Swiper: () => import("@/components/Swiper.vue"),
    SponsorList: () => import("@/components/SponsorList.vue")
  },
  data() {
    let _this = this;
    return {
      loc: '', // 页面定位
      theyear: Object.keys(id_arr).sort((a,b)=>{return b.localeCompare(a)})[0],
      session: session(_this.global.year),
      caseurl: caseurl(_this.global.year),
      sponsorimg: sponsorimg(_this.global.year),
      isinit: true,
      ispc: true,
      swidth: window.innerWidth,
      place: "",
      time: "",
      scheduleData: { address:'', startTime:'', endTime:'' },
      speaker: null,
      speakers: {},
      theme_cli_pc: false,
      theme_cli_mob: false,
      theme: 0,
      themeList: [],
      caseList: [], // 精选案例列表
      caseRender: [], // 渲染的精选案例列表
      caseRandom: [], // 渲染的精选案例索引列表
      renderHistory: [], // 每次点击刷新按钮时记录已渲染过的精选案例的索引
      caseRenderLength: 3, // 案例渲染数目
      refreshTime: 0.35, // 点击刷新按钮刷新时间
      uniteList: [], // 联合主办方
      confereeList: [], // 历史参会企业
      confereeItem: 7,
      conferee: false,
      commentList: [
        "每年参加GIAC是为了看看业界领先的公司都在做些什么，有一些前沿的技术是怎么落地在别人的业务上，这对我们调研新技术、应用新技术都有很多帮助。 -来自华为技术有限公司",
        "参加GIAC，可以现场聆听技术圈的一些大牛的分享，了解大牛们是怎么思考和解决问题的，对拓宽视野，不在研发上走弯路很有用。 -来自平安科技（深圳）有限公司",
        "每年参加GIAC一般都是组团来，每个技术线都会来一些同学，主要是学习一些落地实践，大家都学习进步都快一些，每次会后也组织内部分享，是很好地学习方式。 -来自顺丰科技有限公司"
      ],
      scheduleDifference: scheduledifference(_this.global.year),
    };
  },
  computed: {
    cssVars() {
      return {
        '--refresh-time': this.refreshTime + 's',
      }
    }
  },
  created() {
    this.setInit();
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.winScroll();
    // })
  },
  watch: {
    $route(to, from) {
      if(to.name != from.name) {
        this.setInit();
      } 
      this.loc = this.$route.query.loc || '';
    },
    loc: {
      handler(_new, _old) {
        // console.log(_new, '||', _old, '||', this.$refs['home_sponsor_2024sz'].offsetTop);
        // if(_new && _new.length>0){
        //   this.winScroll();
        // }
        if(!_new || _new.length==0) {
          window.scrollTo(0, 0);
        }
      },
      immediate: true,
    },
    caseList(_new, _old) {
      // console.log(_new, _old, this.caseRenderLength);
      if(_new && _new.length > this.caseRenderLength){
        this.getRandom();
      } else {
        this.caseRender = _new;
      }
    },
  },
  methods: {
    setInit: function() {
      this.isinit = false;
      this.loc = this.$route.query.loc || '';
      this.initsize();
      this.$nextTick(() => {
        this.getPlaceTime();
        this.getChairman(); // 联席主席
        this.getSpeakers(); // 出品人
        this.getTheme();
        // this.getCases();
        this.getSelectedCase();
        this.getUnite(); // 联合主办方
        this.getConferee(); // 历史参会企业
        this.$forceUpdate();
        this.isinit = true;
      })
    },
    winScroll: function() {
      setTimeout(()=>{
        if(!this.loc || this.loc==''){
          window.scrollTo(0, 0);
        } else {
          if(this.loc == 'sponsor'){
            // let header = this.ispc ? 155 : 55;
            let difference = this.ispc ? 40 : -15;
            let top = this.$refs['home_sponsor_2024sz'].offsetTop + difference;
            // console.log('loc ', top);
            window.scrollTo(0, top);
          }
        }
      }, 500)
    },

    initsize: function() {
      this.swidth = window.innerWidth;
      if (this.swidth > 767) {
        this.ispc = true;
        this.theme = 0;
      } else {
        this.ispc = false;
        this.theme = -1;
      }
      // console.log(this.ispc, this.theme);
      this.setresize();
    },
    setresize: function() {
      // this.swidth = window.innerWidth;
      window.addEventListener("resize", () => {
        let swidth = window.innerWidth;
        // pc->mob || mob->mob
        if (
          (this.swidth > 767 && swidth <= 767) ||
          (this.swidth <= 767 && swidth <= 767)
        ) {
          this.ispc = false;
          this.theme_cli_pc = false;
          !this.theme_cli_mob ? this.theme=-1 : "";
        }
        // mob->pc || pc->pc
        if (
          (this.swidth <= 767 && swidth > 767) ||
          (this.swidth > 767 && swidth > 767)
        ) {
          this.ispc = true;
          this.theme_cli_mob = false;
          !this.theme_cli_pc ? this.theme=0 : "";
        }
        // console.log(this.swidth, swidth, this.ispc, this.theme, this.theme_cli_pc, this.theme_cli_mob);
        this.conferee = false;
        if (swidth >= 1200) {
          this.confereeItem = 7;
        } else if (swidth >= 1000) {
          this.confereeItem = 6;
        } else {
          this.confereeItem = 5;
        }
        this.conferee = true;
        this.$forceUpdate();
      });
    },

    setTheme: function(e,index,type) {
      // e.stopPropagation();
      if(type == "pc"){
        this.theme = index;
        this.theme_cli_pc = true;
        this.theme_cli_mob = false;
      } else if(type == "mob"){
        this.theme_cli_pc = false;
        if(this.theme == index){
          this.theme = -1;
          this.theme_cli_mob = false;
        } else {
          this.theme = index;
          this.theme_cli_mob = true;
        }
      }
      // console.log('cli ', this.theme, this.theme_cli_pc, this.theme_cli_mob);
    },

    // 暂未用
    checkBoolean: function(_data) {
      let res = false;
      let data = [];
      // Object || Array   ||   Number || Boolean || null || undefined
      if (typeof _data == "object") {
        data =
          _data.toString() == "[object Object]"
            ? [...Object.values(_data)]
            : _data;
      } else {
        data[0] = _data;
      }
      for (let i of data) {
        console.log(i);
        !i || i == 0 || i == undefined || i == null
          ? (res = false)
          : (res = true);
        if (res == false) break;
      }
      return res;
    },

    // 获取数据
    getPlaceTime: function() {
      this.$http
        .jsonp(getMeetingTime + sid(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res);
          if (res.status === "200") {
            // const start = this.formatTime(res.data.startTime, "start");
            // const end = this.formatTime(res.data.endTime, "end");
            const start = this.global.formatTime(res.data.startTime, "Y.M.D");
            let end = '';
            if(new Date(res.data.endTime*1000).getMonth() == new Date(res.data.startTime*1000).getMonth()){
              end = this.global.formatTime(res.data.endTime, "D");
            } else {
              end = this.global.formatTime(res.data.endTime, "M.D");
            }
            this.place = `${res.data.address}`;
            this.time = `${start}-${end}`;
            this.scheduleData.address = res.data.address;
            this.scheduleData.startTime = res.data.startTime;
            this.scheduleData.endTime = res.data.endTime;
          }
        });
    },
    getChairman: function() {
      this.speakers.chairman = {
        title: "联席主席",
        desc: "带领技术团队走向未来的变革者",
        list: []
      };
      // this.speaker = {};
      // this.speaker.chairman = false;
      this.$http
        .jsonp(getSueLecturer + sidlecturer1(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res);
          if (res.errno == "0") {
            this.speakers.chairman.list = res.data[sidlecturer1(this.global.year)]
              ? res.data[sidlecturer1(this.global.year)]
              : [];
            // this.speaker.chairman = true;
            this.$forceUpdate();
          }
        });
    },
    getSpeakers: function() {
      this.speakers.speaker = {
        title: "出品人",
        desc: "带领技术团队走向未来的变革者",
        list: []
      };
      // this.speaker.speaker = false;
      this.$http
        .jsonp(getSueLecturer + sidlecturer2(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log("268 》》》 ",res);
          if (res.errno == 0) {
            this.speakers.speaker.list = res.data[sidlecturer2(this.global.year)]
              ? res.data[sidlecturer2(this.global.year)]
              : [];
            // this.speaker.speaker = true;
            this.$forceUpdate();
          } else {
            this.speakers.speaker.list = [];
          }
        });
    },
    getTheme: function() {
      this.$http
        .jsonp(getThemeInfo + sid(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res)
          if (res.errno == 0) {
            this.themeList = this.splitTheme(res.data, 4);
            console.log(this.themeList);
            this.getDifference();
          }
        });
    },
    splitTheme(themeList, num) {
      const list = [];
      for (let i = 0; i < themeList.length; ) {
        list.push(themeList.slice(i, (i += num)));
      }
      return list;
    },

    getDifference() {
      let url = getSueType + scheduleDifferenceId;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log("difference ", res);
          if (res.errno == 0 && res.data) {
            let type = '';
            let typeList = [];
            for(let item of res.data) {
              type = item.typeName && typeof item.typeName==='string' && item.typeName.toLocaleLowerCase();
              if(type && type.includes('giac')) {
                typeList.push({id:item.id, name:type.split('#')[1]});
              }
            }
            this.getScheduleDifference(typeList);
          }
        })
    },
    getScheduleDifference(typeList) {
      for(let index in Object.keys(this.scheduleDifference)) {
        this.scheduleDifference[Object.keys(this.scheduleDifference)[index]].courses = [];
        this.$forceUpdate();
      }
      console.log("difference ", typeList);
      let url = getSueArr + scheduleDifferenceId;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log("difference ", res);
          if (res.errno == 0 && res.data) {
            for(let i in this.themeList) {
              let theme={}, differenceKey='';
              for(let j in this.themeList[i]) {
                theme = this.themeList[i][j];
                differenceKey = this.setDifferenceKey(theme.date, theme.groupId, theme.times);
                for(let typeItem of typeList) {
                  // console.log(this.themeList[i][j-1], theme, typeItem);
                  if(this.scheduleDifference[typeItem.name] && typeItem.name==differenceKey) {
                    this.scheduleDifference[typeItem.name].courses = [];
                    let course = {}, lecturers=[], lecturer='', times=[], titles=[], title='';
                    for(let index in res.data[0]) {
                      let course = res.data[0][index];
                      // console.log(course);
                      // schedule-difference
                      lecturers = course.content && course.content.length>0 && course.content.split(' /// ');
                      if(course.content_note && course.content_note.length>0) {
                        times = (course.content_note.split('scheduleTitle--')[0] || '').replace('time--','').replace(/\r\n/g,'').split('-');
                        // titles = (course.content_note.split('scheduleTitle--')[1] || '').replace(/^\r\n/,'').split('\r\n');
                      }
                      this.scheduleDifference[typeItem.name].courses.push({
                        averageScore: null,
                        calculationTime: null,
                        title: course.title,
                        courseTitle: course.title,
                        courseid: '',
                        date: theme.date,
                        startTime: times[0],
                        endTime: times[1],
                        hash: null,
                        id: '',
                        name: lecturers[0].split(' || ')[0] && lecturers[0].split(' || ')[0]!=='客户技术高层' ? lecturers[0].split(' || ')[0] : '',
                        company: lecturers[0].split(' || ')[0] && lecturers[0].split(' || ')[0]!=='客户技术高层' && lecturers[0].split(' || ')[1] ? lecturers[0].split(' || ')[1] : '',
                        position: lecturers[0].split(' || ')[0] && lecturers[0].split(' || ')[0]!=='客户技术高层' && lecturers[0].split(' || ')[2] ? lecturers[0].split(' || ')[2] : '',
                        thumbs: '',
                        sid: sid(this.global.year),
                        snid: theme.id,
                        sort: '0',
                        tag: 'default',
                      });
                      this.themeList[i][j].schedulingCourse = [ ...this.scheduleDifference[typeItem.name].courses ];
                    }
                  }
                }
              }
            }
            this.$forceUpdate();
          }
        })
      // ////
    },
    isDifference(day, groupid, index) {
      let flag = false;
      if(this.scheduleDifference && typeof this.scheduleDifference==='object') {
        let key = this.setDifferenceKey(day, groupid, index);
        let keys = Object.keys(this.scheduleDifference);
        if(keys.includes(key)) {
          flag = true;
        }
      }
      return flag;
    },
    setDifferenceKey(date, groupid, times) {
      let theDate = this.global.formatTime(date, 'YMD')
      let startDate = this.global.formatTime(this.scheduleData.startTime, 'YMD');
      let endDate = this.global.formatTime(this.scheduleData.endTime, 'YMD');
      let day = theDate>=startDate && theDate<endDate ? 1 : 2;
      let index = times.slice(0,2).includes(':') || times.slice(0,2)<'12' ? 0 : 1;
      let period = index===0||index===1 ? ['am', 'pm'][index] : ''
      let key = `${this.global.year}_${day}_${groupid}_${period}`
      return key;
    },

    getSelectedCase() {
      this.$http
        .jsonp(getSueLecturer + sidcase(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(sidcase(this.global.year), res.data);
          let idList = [];
          if (res.errno === "0" && res.data) {
            idList = res.data[sidcase(this.global.year)].map(item => item.content);
          }
          this.getCases(idList);
        });
    },
    getCases: function(_idList) {
      this.caseList = [];
      let idList = _idList;
      this.$http
        .jsonp(getAllCourse + sid(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(idList, res);
          if (res.errno === "0") {
            // this.caseList = res.data;
            if(res.data && res.data.length>0){
              idList && idList.length > 0
                ? res.data.map(item => {
                    if (idList.indexOf(item.courseid) >= 0) {
                      this.caseList.push(item);
                    }
                  })
                : this.caseList = [...res.data];
            }
            this.caseRenderLength = this.caseList.length + 1; // 隐藏案例列表刷新功能
          }
        });
    },
    getUnite() {
      this.$http
        .jsonp(getSue + sidsponsorunite(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res);
          if (res.errno == "0") {
            // 联合主办方
            this.uniteList = [...res.data[0]];
            // this.conferee = true;
          }
        });
    },
    getConferee() {
      this.$http
        .jsonp(getSue + sidsponsorhistory(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res);
          if (res.errno == "0") {
            // 历史参会企业
            this.confereeList = [...res.data[0]];
            this.conferee = true;
            // this.confereeList.push(...res.data[0]); this.confereeList.push(...res.data[0]); this.confereeList.push(...res.data[0]); this.confereeList.push(...res.data[0]);
            // console.log(this.confereeList);
          }
        });
    },

    refreshCases(e) {
      $(e.target).addClass('rotate');
      this.caseRender = [];
      if(this.renderHistory.length == this.caseList.length){
        this.renderHistory = [];
        // console.log('------ ', this.caseRandom);
        for(let i of this.caseRandom){
          this.renderHistory.push(i);
          this.caseRender.push(this.caseList[i]);
        }
      } else {
        for(let i=0;i<this.caseRenderLength;i++){
          // console.log(i, this.renderHistory);
          for(let c in this.caseList){
            if(!this.renderHistory.includes(parseInt(c))){
              this.renderHistory.push(parseInt(c));
              this.caseRender.push(this.caseList[c]);
              break;
            }
          }
        }
      }
      setTimeout(() => {
        $(e.target).removeClass('rotate');
      }, this.refreshTime*1000);
    },
    getRandom() {
      let randomPre = localStorage.getItem('case_random');
      let randomArrPre = randomPre && randomPre.split(',') || []; // 上一次渲染索引列表
      this.renderHistory = [];
      this.caseRender = [];
      let num=0, randomArrNow=[]; // 随机数、当前渲染索引列表
      for(let i=0; i<this.caseRenderLength; i++){
        num = this.global.setRandom(0, this.caseList.length-1);
        if(!num || randomArrNow.includes(num) || randomArrPre.includes(num)) {
          i--;
        } else {
          randomArrNow.push(num);
        }
      }
      this.caseRandom = randomArrNow;
      localStorage.setItem('case_random', randomArrNow);
      for(let index of randomArrNow){
        this.renderHistory.push(index);
        this.caseRender.push(this.caseList[index])
      }
    },

    toOrder() {
      this.$router.push({ path: `/2024sz/order` });
    },
    _toOrder: function() {
      const token = localStorage.getItem("token");
      console.log(token);
      if (token && token !== null) {
        let url = `${getUserInfo}?token=${token}`;
        this.$http
          .jsonp(url)
          .then(data => {
            return data.json();
          })
          .then(res => {
            console.log("home- checkUserInfo", res);
            if (res.status === "200") {
              let flag = true;
              for (const key in res.data) {
                if (res.data[key] === "") {
                  flag = false;
                  if (window.confirm("购票需要您先完善个人信息")) {
                    let link = window.location.pathname + window.location.search;
                    localStorage.setItem('link_before_complete', link);
                    this.$router.push({
                      path: "/2024sz/userCenter",
                      // query: { active: "5" },
                      query: { selected: "2" },
                    });
                  }
                  break;
                }
              }
              if (flag) {
                this.global.hashParam.qd
                ? this.$router.push({
                    path: `/2024sz/order`,
                    query: {qd: this.global.hashParam.qd}
                  })
                : this.$router.push({
                    path: `/2024sz/order`,
                  });
              }
            } else if (res.code === "1001" || res.status === "1011") {
              this.$message({
                message: "当前登录状态已过期，请重新登录",
                type: "warning"
              });
              window.localStorage.removeItem("logins");
              window.localStorage.removeItem("token");
              let order = `/2024sz/order`+`${this.global.hashParam.qd?'?qd='+this.global.hashParam.qd:''}`;
              window.localStorage.setItem("linkurl", order);
              setTimeout(() => {
                this.$router.push({ path: "/2024sz/login" });
              }, 2000);
            } else {
              console.log("获取用户信息失败");
            }
          });
      } else {
        this.$message({
          message: "当前登录状态已过期，请重新登录",
          type: "warning"
        });
        window.localStorage.removeItem("logins");
        window.localStorage.removeItem("token");
        let order = `/2024sz/order`+`${this.global.hashParam.qd?'?qd='+this.global.hashParam.qd:''}`;
        window.localStorage.setItem("linkurl", order);
        setTimeout(() => {
          this.$router.push({ path: "/2024sz/login" });
        }, 2000);
      }
    },
    toNext: function(path, query) {
      this.$router.push({
        path: path,
        query: query
      });
    },
    toNew: function(url) {
      if(url && url!=""){
        window.open(url,"_blank");
      }
    },
  }
};
</script>

<style lang="scss">
.home_ {
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  a:hover,
  a:visited,
  a:focus {
    color: inherit;
    text-decoration: none;
  }
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
  }
  .banner {
    width: 100%;
    height: 777px;
    padding: 130px 0 0 0;
    // height: 685px;
    // padding: 135px 0 0 0;
    background-color: #5051E9;
    // background-image: url("../assets/img/giac2022_banner_777.png");
    background-image: url("../assets/img/giac2022_banner_777_small.png");
    // background-image: url("https://f.msup.com.cn/meeting/giac2022_banner_777.png");
    // background-image: var(--home-banner);
    background-repeat: no-repeat;
    background-position: right;
    // background-size: auto 100%;
    background-size: cover;
    position: relative;
    .main {
      width: 100%;
      max-width: 1240px;
      margin: 0 auto;
      padding: 0 20px;
      h1 {
        width: fit-content;
        min-height: 80px;
        line-height: 80px;
        font-size: 80px;
        font-family: Source Han Sans CN Heavy;
        font-weight: 800;
        color: #ffffff;
        margin-top: 35px;
        margin-bottom: 0;
        p {
          width: 100%;
          min-height: 20px;
          line-height: 20px;
          margin-top: 25px;
          margin-bottom: 0;
          font-size: 20px;
          font-family: Arial;
          font-weight: bold;
          color: #ffffff;
          letter-spacing: 5px;
          text-align-last: justify;
        }
      }
      > p {
        min-height: 20px;
        line-height: 20px;
        margin-top: 50px;
        margin-bottom: 0;
        font-size: 20px;
        font-family: Arial;
        font-weight: bold;
        color: #ffffff;
        span {
          height: 67px;
          line-height: 67px;
          font-size: 40px;
          font-family: Alibaba PuHuiTi Medium;
          font-weight: 500;
          color: #ffffff;
          padding-right: 27px;
          background: linear-gradient(
            -90deg,
            rgba(78, 168, 151, 0.96) 10%,
            rgba(72, 73, 223, 0.72) 65%,
            rgba(69, 74, 206, 0.59) 100%
          );
          border-radius: 34px;
          display: inline-block;
        }
      }
      div.time {
        margin-top: 45px;
        display: flex;
        flex-wrap: nowrap;
        p {
          min-height: 25px;
          line-height: 25px;
          font-size: 25px;
          font-family: Alibaba PuHuiTi Regular; // Light
          font-weight: bold;
          color: #ffffff;
          margin-right: 40px;
          margin-bottom: 0;
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          img {
            width: auto;
            height: 30px;
            margin-right: 15px;
          }
        }
      }
      div.btns {
        margin-top: 40px;
        display: flex;
        flex-wrap: nowrap;
        p {
          width: 264px;
          line-height: 66px;
          margin-right: 30px;
          font-size: 20px;
          // font-family: Source Han Sans CN Regular;
          font-family: Alibaba PuHuiTi Regular;
          font-weight: 400;
          text-align: center;
          color: #FFFFFF;
          background: linear-gradient(0deg, #0029BC 0%, #495FFF 100%);
          border-radius: 8px;
          cursor: pointer;
        }
        p.act {
          color: #3942B7;
          background: #FFFFFF;
        }
      }
    }
  }
  .giac-container {
    width: 100%;
    .main {
      width: 100%;
      max-width: 1400px;
      padding: 0 25px;
      margin: 0 auto;
      > h1,
      h1.head_title,
      .source_home .title {
        width: 100%;
        // height: 32px;
        line-height: 32px;
        font-size: 32px;
        font-family: PingFang SC Bold;
        font-weight: bold;
        color: #00081a;
        text-align: center;
      }
      > p,
      p.head_title,
      .source_home .desc {
        width: 100%;
        max-width: 757px;
        margin: 0 auto;
        margin-top: 15px;
        line-height: 28px;
        font-size: 19px;
        font-family: PingFang SC Regular;
        font-weight: 400;
        color: #777777;
        text-align: center;
      }
    }
    p.more {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      font-size: 24px;
      font-family: PingFang SC Medium;
      font-weight: 500;
      color: #2630b2;
      text-align: center;
      background: linear-gradient(0deg, #5da5ff 0%, #2b228d 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .top {
    padding-bottom: 75px;
    top: -63px;
    position: relative;
    background-color: transparent;
    .main {
      position: relative;
      z-index: 2;
      .desc {
        width: 100%;
        max-width: 1330px;
        padding: 50px 60px;
        margin: 0 auto;
        background-color: #ffffff;
        box-shadow: 0px 0px 14px 0px rgba(110, 110, 110, 0.13);
        border-radius: 12px;
        display: flex;
        flex-wrap: nowrap;
        .le {
          width: 100%;
          padding-right: 50px;
          h2 {
            height: 22px;
            line-height: 22px;
            font-size: 22px;
            font-family: PingFang SC Bold;
            font-weight: bold;
            color: #2630b2;
          }
          p {
            line-height: 25px;
            font-size: 14px;
            font-family: PingFang SC Medium;
            font-weight: 500;
            color: #666666;
            margin-top: 10px;
          }
        }
        .ri {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-evenly;
          border-left: 1px solid #d1d1d1;
          .titem {
            h1 {
              width: fit-content;
              height: 55px;
              line-height: 30px;
              font-size: 42px;
              font-family: Arial;
              font-weight: 400;
              color: #444444;
              display: grid;
            }
            h1::after {
              content: "";
              display: inline-block;
              width: 40px;
              height: 3px;
              margin-top: 20px;
              background-color: #2630b2;
            }
            p {
              width: fit-content;
              line-height: 21px;
              font-size: 15px;
              font-family: Alibaba PuHuiTi;
              font-weight: 400;
              color: #666666;
              margin-top: 10px;
            }
          }
        }
      }
      .more {
        padding-top: 20px;
        height: 44px;
        line-height: 24px;
      }
    }
    .hshadow {
      width: 100%;
      height: 467px;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(0deg, #f6f8fe 1%, #ffffff 53%);
    }
  }
  .theme-setting {
    .giac-container {
      .main {
        // max-width: 1250px;
        div.list {
          display: flex;
          flex-wrap: nowrap;
          margin-top: 65px;
          margin-right: -35px;
          .litem {
            width: 100%;
            margin-right: 35px;
            line-height: 25px;
            font-size: 17px;
            font-family: PingFang SC Regular;
            font-weight: 400;
            color: #999999;
            text-align: center;
            border-bottom: 5px solid transparent;
            cursor: pointer;
          }
          .litem.act {
            color: #2630b2;
            border-color: #2630b2;
            background: linear-gradient(
              0deg,
              #f4f6fd 0%,
              #ffffff 57.99999999999999%
            );
          }
        }
        div.intro {
          width: 100%;
          height: 100%;
          // display: flex;
          // flex-wrap: nowrap;
          display: table;
          margin-bottom: 18px;
          background-color: #ffffff;
          > div {
            width: 100%;
          }
          .left {
            width: 252px;
            min-width: 252px;
            max-width: 252px;
            padding: 55px 0;
            display: table-cell;
            vertical-align: middle;
            background-color: #f3f5fb;
            h1,
            p {
              width: 100%;
              text-align: center;
            }
            h1 {
              line-height: 22px;
              font-size: 22px;
              font-family: PingFang SC Medium;
              font-weight: 500;
              color: #00081a;
            }
            p {
              margin-top: 20px;
              line-height: 18px;
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
          .right {
            // display: flex;
            // flex-wrap: nowrap;
            // width: fit-content;
            width: 100%;
            height: 100%;
            display: table;
            vertical-align: middle;
            padding: 30px 0;
            .course {
              height: 100%;
              display: table-cell;
              vertical-align: middle;
              // display: table;
              position: relative;
              .box.none {
                top: 0;
                left: 0;
                position: absolute;
                vertical-align: middle;
                text-align: center;
                z-index: 1;
                h1 {
                  top: 50%;
                  left: 0;
                  // left: 50%;
                  margin-top: -11px;
                  // margin-left: -115px;
                  position: absolute;
                }
              }
              .box {
                width: 100%;
                height: 100%;
                padding: 0 10px;
                position: relative;
                z-index: 2;
                background-color: #ffffff;
                h1,
                h2,
                p {
                  margin: 0 auto;
                }
                h2,
                p {
                  max-width: 230px;
                  cursor: pointer;
                }
                h1 {
                  width: 100%;
                  line-height: 22px;
                  font-size: 22px;
                  font-family: PingFang SC Medium;
                  font-weight: 500;
                  color: #3b44b9;
                  text-align: center;
                }
                h2 {
                  // height: 41px;
                  line-height: 25px;
                  font-size: 17px;
                  font-family: PingFang SC Medium;
                  font-weight: 500;
                  color: #3b44b9;
                }
                p {
                  margin-top: 10px;
                  line-height: 25px;
                  font-size: 15px;
                  font-family: PingFang SC Regular;
                  font-weight: 500;
                  color: #999999;
                }
              }
            }
            .course:not(:nth-of-type(1)) {
              .box {
                border-left: 1px solid #cccccc;
              }
            }
          }
        }
      }
    }
    .giac-container:nth-of-type(1) {
      overflow: hidden;
      background-color: #ffffff;
      .main {
        margin-top: 14px; // 77-63
      }
    }
    .giac-container:nth-of-type(2) {
      background-color: #f7f9fe;
      padding: 47px 0 70px 0;
    }
  }
  .cases {
    .main {
      max-width: 1280px;
      margin-top: 65px;
      padding: 0;
      padding-bottom: 77px;
      > h1 {
        position: relative;
        .refresh {
          width: 25px;
          height: 25px;
          object-fit: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: 105px;
          margin-top: -13px;
          cursor: pointer;
          &.rotate {
            animation: rotate_180  linear;
            animation-duration: var(--refresh-time);
          }
        }
        @keyframes rotate_180 {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(180deg);
          }
        }
      }
      > p {
        margin-bottom: 50px;
      }
      .case-wrapper {
        padding-top: 20px;
        text-align: center;
        .all-case {
          width: 240px;
          height: 60px;
          line-height: 60px;
          font-size: 20px;
          // font-family: Source Han Sans CN Regular;
          font-family: Alibaba PuHuiTi Regular;
          font-weight: 400;
          color: #ffffff;
          display: inline-block;
          border-radius: 8px;
          // background: linear-gradient(0deg, #0029bc 0%, #495fff 100%);
          background: #003177;
        }
      }
    }
  }
  .comment-content {
    min-height: 440px;
    position: relative;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      top: 0;
      left: 0;
      z-index: 1;
      position: absolute;
    }
    .main {
      padding-top: 80px;
      z-index: 2;
      position: relative;
      > h1 {
        margin-bottom: 45px;
        color: #ffffff;
      }
    }
  }
  .conferee {
    background-color: #f7f9fe;
    .main {
      max-width: 1480px;
      padding-top: 75px;
      padding-bottom: 50px;
      .pc, .mob {
        width: 100%;
      }
      .pc {
        .companys {
          width: 100%;
          margin-top: 70px;
          position: relative;
          .left,
          .right {
            width: 120px;
            height: 100%;
            top: 0;
            z-index: 2;
            position: absolute;
          }
          .left {
            left: 0;
            background: linear-gradient(90deg, #f7f9fe 0%, transparent 100%);
          }
          .right {
            right: 0;
            background: linear-gradient(-90deg, #f7f9fe 0%, transparent 100%);
          }
          .list {
            width: 100%;
            z-index: 1;
            position: relative;
            .line:nth-of-type(2n + 1) {
              padding-left: 30px;
            }
            .line:nth-of-type(2n) {
              padding-right: 30px;
            }
            .line.one {
              justify-content: center;
            }
            .line {
              width: 100%;
              height: 80px;
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 45px;
              .litem {
                // width: 100/7; // 14.2857%
                // height: 100%;
                // min-height: 80px;
                height: 80px;
                padding: 0 10px;
                display: table;
                overflow: hidden;
                .box {
                  width: 100%;
                  // height: 100%;
                  display: table-cell;
                  vertical-align: middle;
                  background-color: #ffffff;
                  box-shadow: 0px 0px 14px 0px rgba(43, 66, 131, 0.07);
                  border-radius: 8px;
                  overflow: hidden;
                  img {
                    width: 100%;
                    // height: 100%;
                    object-fit: contain;
                    object-position: center;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
      .mob {
        .companys {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          // justify-content: space-between;
          .citem {
            // width: 155px;
            // height: 69px;
            width: 23.4%;
            min-width: 150px;
            height: 55px;
            margin: 0 5px;
            margin-top: 15px;
            background: #FFFFFF;
            box-shadow: 0px 0px 14px 0px rgba(43, 66, 131, 0.07);
            border-radius: 8px;
            overflow: hidden;
            .box {
              width: 100%;
              text-align: center;
              img {
                width: auto;
                max-width: 100%;
                height: 55px;
                object-fit: contain;
                object-position: center;
              }
            }
          }
        }
      }
    }
  }
  .sponsor {
    .main {
      .source_home {
        padding-top: 40px;
        .sponsor {
          .sponsor-option {
            ul {
              margin-bottom: 0;
            }
            p.title {
              // padding: 75px 0;
              padding: 35px 0 50px 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .invite {
    background: linear-gradient(0deg, #3333c8 0%, #5657ee 100%);
    .pc, .mob {
      width: 100%;
    }
    .pc {
      .main {
        max-width: 1135px;
        padding-top: 32px;
        padding-bottom: 32px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        > img {
          width: auto;
        }
        > p {
          max-width: 100%;
          margin-top: 0;
          line-height: 55px;
          font-size: 22px;
          font-family: PingFang SC Medium;
          font-weight: 500;
          color: #ffffff;
          opacity: 0.8;
        }
        .link {
          display: flex;
          flex-wrap: nowrap;
          a p {
            width: 162px;
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            font-family: PingFang SC Bold;
            font-weight: bold;
            text-align: center;
            cursor: pointer;
          }
          a:nth-of-type(1) p {
            color: #2630b2;
            background: #ffffff;
            border-radius: 5px 5px 5px 5px;
          }
          a:nth-of-type(2) p {
            margin-left: 13px;
            color: #ffffff;
            background: #1c1788;
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 5px 5px 5px 5px;
          }
        }
      }
    }
    .mob {
      .main {
        display: flex;
        flex-wrap: nowrap;
        padding-top: 15px;
        padding-bottom: 15px;
        .left {
          width: 100%;
          img {
            height: 30px;
          }
          p {
            margin-top: 5px;
            margin-bottom: 0;
            line-height: 20px;
            font-size: 14px;
            font-family: PingFang SC Bold;
            font-weight: bold;
            color: #FFFFFF;
          }
        }
        .link {
          width: 175px;
          min-width: 175px;
          max-width: 175px;
          padding-left: 20px;
          a {
            p {
              width: 100%;
              height: 30px;
              margin-bottom: 0;
              line-height: 28px;
              font-size: 14px;
              font-family: PingFang SC Bold;
              font-weight: bold;
              text-align: center;
              border: 1px solid #FFFFFF;
              border-radius: 6px;
            }
          }
          a:nth-of-type(1) {
            p {
              margin-bottom: 8px;
              color: #2630B2;
              background: #FFFFFF;
            }
          }
          a:nth-of-type(2) {
            p {
              color: #FFFFFF;
              background: #1C1788;
            }
          }
        }
      }
    }
  }
}

.pc {
  display: block;
}
.mob {
  display: none;
}

@media (max-width:1024px) and (min-width: 767px) {
  .home_ .theme-setting .giac-container .main div.intro .left {
    width: 170px;
    min-width: 170px;
    max-width: 170px;
  }
}
@media (max-width:915px) and (min-width: 767px) {
  .home_ .invite .pc .main >p {
    line-height: 25px;
    padding: 0 5px;
  }
}

@media (max-width: 767px) {
  .pc {
    display: none;
  }
  .mob {
    display: block;
  }
  .home_ {
    .giac-container {
      .main {
        padding: 0 20px;
        > h1,
        h1.head_title,
        .source_home .title {
          height: 20px;
          line-height: 20px;
          font-size: 20px;
        }
        > p,
        p.head_title,
        .source_home .desc {
          margin-top: 8px;
          min-height: 13px;
          line-height: 16px;
          font-size: 13px;
        }
      }
    }
    .banner {
      height: auto;
      min-height: 265px;
      padding: 30px 0 60px 0;
      // padding: 40px 0 60px 0;
      // padding: 50px 0 60px 0;
      // background-image: url("../assets/img/giac2022_banner_mob.png");
      background-image: url("../assets/img/giac2022_banner_mob_small.png");
      // background-image: url("https://f.msup.com.cn/meeting/giac2022_banner_mob.png");
      .main {
        img {
          width: auto;
          height: 25px;
        }
        > h1 {
          min-height: 27px;
          line-height: 27px;
          font-size: 27px;
          margin-top: 14px;
          p {
            // 16px
            height: 12px;
            min-height: 0;
            line-height: 12px;
            font-size: 12px;
            font-weight: 300;
            margin-top: 5px;
            letter-spacing: 0;
          }
        }
        > p {
          min-height: 0;
          margin-top: 13px;
          // margin-top: 15px;
          span {
            // 46px 28px 18px
            height: 23px;
            line-height: 23px;
            font-size: 14px;
            padding-right: 9px;
          }
        }
        div.time {
          margin-top: 12px;
          // margin-top: 20px;
          display: inline-block;
          p {
            // 17px
            min-height: 15px;
            line-height: 15px;
            font-size: 14px;
            margin-right: 0;
            margin-bottom: 8px;
            img {
              // 21px
              height: 17px;
              margin-right: 0;
              // margin-right: 5px;
            }
          }
          p:nth-of-type(1) {
            margin-right: 10px;
          }
        }
        div.btns {
          // margin-top: 15px;
          margin-top: 10px;
          p {
            width: 140px;
            margin-right: 10px;
            margin-bottom: 0;
            line-height: 25px;
            font-size: 13px;
            border-radius: 6px;
          }
        }
      }
    }
    .top {
      // top: -41px;
      top: -38px;
      padding-bottom: 10px;
      position: relative;
      .main {
        padding: 0;
        .desc {
          // padding: 15px 20px;
          padding: 25px 20px;
          background-image: url("../assets/img/home_meeting_desc_bg.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 117.5% 117.5%;
          background-color: transparent;
          box-shadow: none;
          .le {
            display: none;
          }
          .ri {
            width: 100%;
            border: none;
            background-color: transparent;
            .titem:not(:nth-of-type(1)) {
              border-left: 1px solid #000000;
            }
            .titem {
              width: 33.33%;
              height: 44px; // 64px
              padding: 0 5px;
              h1::after {
                display: none;
              }
              h1 {
                width: 100%;
                margin-top: -10px;
                height: 26px;
                line-height: 26px;
                font-size: 26px;
                font-weight: bold;
                color: #2630b2;
                text-align: center;
              }
              p {
                width: 100%;
                margin-top: 5px;
                line-height: 16px;
                font-size: 12px;
                text-align: center;
              }
            }
          }
        }
        > p.mob {
          padding: 0 20px;
          margin-top: 20px;
          line-height: 20px;
          font-size: 14px;
          font-family: Alibaba PuHuiTi Regular;
          font-weight: 400;
          text-align: left;
          text-indent: 2em;
          span {
            font-family: Alibaba PuHuiTi Medium;
            font-weight: bold;
            color: #2630b2;
          }
        }
      }
      .hshadow {
        height: 110px;
        opacity: 0.4;
        background: linear-gradient(0deg, #e1e5f2 1%, #ffffff 60%);
      }
    }
    .theme-setting {
      .giac-container:nth-of-type(1) {
        .main {
          margin-top: 0;
        }
      }
      .giac-container.mob {
        width: 100%;
        margin-top: 15px;
        .theme {
          width: 100%;
          .theme_head.act {
            color: #2630b2;
          }
          .theme_head {
            width: 100%;
            padding: 17px 20px;
            padding-right: 32px;
            line-height: 14px;
            font-size: 14px;
            font-family: PingFang SC Medium;
            font-weight: 500;
            color: #999999;
            background: #f7f9fe;
            border: 1px solid #ffffff;
            position: relative;
            cursor: pointer;
            span {
              width: 9px;
              height: 9px;
              top: 17px;
              right: 20px;
              position: absolute;
              display: inline-block;
              border-top: 2px solid #2630b2;
              border-left: 2px solid #2630b2;
              transform: rotate(-135deg);
            }
            span.open,
            span.close {
              opacity: 1;
            }
            span.open {
              top: 21px;
              transform: rotate(45deg);
            }
            span.close {
              border-color: #999999;
            }
          }
          .theme_detail {
            width: 100%;
            >div {
              width: 100%;
            }
            .theme_title {
              width: 100%;
              padding: 0 20px;
              line-height: 40px;
              font-size: 14px;
              font-family: PingFang SC Medium;
              font-weight: 500;
              color: #FFFFFF;
              text-align: center;
              background: #2630B2;
              border: 1px solid #FFFFFF;
            }
            .theme_course {
              width: 100%;
              padding: 20px;
              border: 1px solid #FFFFFF;
              background: linear-gradient(165deg, #F6F8FC 0%, #FFFFFF 100%);
              h3 {
                margin-bottom: 0;
                line-height: 20px;
                font-size: 14px;
                font-family: PingFang SC Medium;
                font-weight: 500;
                color: #3B44B9;
              }
            }
            .theme_course p, .no_course {
              margin-bottom: 0;
              margin-top: 10px;
              line-height: 13px;
              font-size: 13px;
              font-family: PingFang SC;
              // font-family: PingFang SC Regular;
              font-weight: 500;
              color: #999999;
            }
            .no_course {
              text-align: center;
              padding-bottom: 20px;
            }
          }
        }
      }
    }
    .cases {
      .main {
        margin-top: 30px;
        padding-bottom: 35px;
        >p {
          margin-bottom: 25px;
        }
        .case-wrapper {
          padding: 0 15px;
          padding-top: 10px;
          .all-case{
            width: 100%;
            max-width: 300px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            // background: #2630B2;
            background-color: #003177;
          }
        }
      }
    }
    .comment-content {
      min-height: 300px;
      .main {
        padding-top: 40px;
        >h1 {
          margin-bottom: 25px;
        }
      }
    }
    .conferee {
      background-color: #FFFFFF;
      .main {
        margin-top: 35px;
        padding: 0 15px;
        >p {
          padding-bottom: 10px;
        }
        .mob .companys .citem .box {
          padding: 0px 8px;
        }
      }
    }
    .sponsor {
      .main{
        padding: 0 15px;
        .source_home {
          padding-top: 0;
        }
      }
    }
  }
}
</style>